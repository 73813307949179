<template>
    <LargeColumnWithSidebar>
        <div
            v-if="remindersToday && remindersToday.length > 0"
            class="remindersToday mb-10 text-center"
        >
            <p class="text-3xl">
                Du har {{ remindersToday.length }} {{ remindersToday.length > 1 ? 'saker' : 'sak' }} att komma ihåg idag.
                <br>
                <router-link
                    to="/kom-ihag"
                    class="link font-bold"
                >
                    Gå till kom ihåg
                </router-link>
            </p>
        </div>

        <Calendar />

        <template v-slot:sidebar>
            <Reminders />
        </template>
    </LargeColumnWithSidebar>
</template>

<script>
import LargeColumnWithSidebar from '@/components/layout/LargeColumnWithSidebar';
import Calendar from '@/components/calendar/Calendar';
import Reminders from '@/components/reminders/Reminders';
import ReminderService from '@/services/ReminderService';

export default {
    components: {
        LargeColumnWithSidebar,
        Calendar,
        Reminders
    },

    data: () => ({
        remindersToday: null
    }),

    async mounted () {
        const reminders = await ReminderService.today();

        this.remindersToday = reminders.data;
    }
};
</script>

<style lang="postcss" scoped>
.reminders >>> .top {
    @media (--smallTablet) {
        display: none;
    }
}

.remindersToday {
    display: none;

    @media (--mobile) {
        display: block;
    }
}

.link {
    color: var(--primaryColor);
}
</style>
